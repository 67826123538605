<template>
  <div>
    <b-table-simple
      hover
      caption-top
      caption-bottom
      responsive
      bordered
      small
    >
      <b-thead>
        <b-tr>
          <b-th
            class="font-small-2 text-nowrap align-middle"
          >
            MODEL
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            TOPLAM STOK
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            BAĞLANTI
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            BOŞTA
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            0-30
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            31-60
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            61+
          </b-th>
          <b-th class="font-small-2 text-nowrap align-middle width-100 text-center">
            VALÖR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key) in modelsData">
          <b-tr
            :key="key"
          >
            <b-td class="font-small-2">
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                @click="item.detailToggle = ! item.detailToggle"
              >
                <FeatherIcon
                  :icon="!item.detailToggle? 'EyeIcon' : 'EyeOffIcon'"
                />
              </b-button>
              {{ item.model }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.total_count }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.connected }}
            </b-td>
            <b-td
              class="font-small-2 text-center font-weight-bold"
              :class="(item.total_count - item.connected) > 0? 'bg-light-success' : 'bg-light-danger'"
            >
              {{ item.total_count - item.connected }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.days_0_30 }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.days_31_60 }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.days_61 }}
            </b-td>
            <b-td class="font-small-2 text-center">
              {{ item.value_date }}
            </b-td>
          </b-tr>
          <b-tr
            v-if="item.detailToggle"
            :key="'detail_' + key"
          >
            <b-td
              colspan="8"
              class="m-0 p-0"
            >
              <detail-table :detail-data="item.details" />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th class="font-small-2">
            Toplam
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.total_count }}
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.connected }}
          </b-th>
          <b-th
            class="font-small-2 text-center"
            :class="(totalsData.total_count - totalsData.connected) > 0? 'bg-light-success' : 'bg-light-danger'"
          >
            {{ totalsData.total_count - totalsData.connected }}
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.days_0_30 }}
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.days_31_60 }}
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.days_61 }}
          </b-th>
          <b-th class="font-small-2 text-center">
            {{ totalsData.value_date }}
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr, BButton,
} from 'bootstrap-vue'
import DetailTable from '@/views/Reports/Sales/SalesGPR/StockList/DetailTable.vue'

export default {
  name: 'Tables',
  components: {
    BTableSimple,
    BTd,
    BTfoot,
    BTbody,
    BTh,
    BThead,
    BTr,
    BButton,
    DetailTable,
  },
  props: {
    modelsData: {
      type: Array,
      required: true,
    },
    totalsData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
  },
}
</script>
